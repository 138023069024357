import { _js } from '@ifixit/localize';
import { useContext } from 'react';
import styled from 'styled-components';
import { color, fontSize } from '@ifixit/primitives';
import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser';
import { FaIcon } from '@ifixit/icons';
import { BreakpointsContext } from 'Shared/utils/breakpoints';

declare const Auth: any;
declare const Utils: any;

const UserButton = styled.button`
   svg {
      transition: color 0.1s;
   }

   &:hover svg {
      color: ${color.white};
   }
`;

const Text = styled.a`
   margin: 0 16px;
   font-weight: normal;
   font-size: ${fontSize.md};
   transition: color 0.1s;
   color: ${color.white};

   &:hover {
      color: ${color.blue[400]};
   }
`;

const Container = styled.div`
   position: relative;
   display: flex;
   justify-content: space-between;
   align-items: center;

   ${Text} {
      text-decoration: none;
      white-space: nowrap;
   }
`;

const Divider = styled.div`
   flex-shrink: 0;
   width: 1px;
   height: 24px;
   background: ${color.gray[700]};
`;

const logIn = () => {
   Auth.login({
      from: 'navLogin',
      message: _js('Logging in will reload the page.'),
      reload: !Utils.hasUnsavedChanges(),
      register: false,
   });
};

const AuthIcons = () => {
   if (App.disableHeaderLoginLinks) {
      return null;
   }
   const breakpoints = useContext(BreakpointsContext);

   if (breakpoints.isMobileTablet()) {
      return (
         <UserButton aria-label="Log in" role="button" onClick={logIn}>
            <FaIcon
               icon={faUser}
               focusable="false"
               color={color.white}
               boxSize={{ base: '20px', sm: '24px' }}
            />
         </UserButton>
      );
   }

   return (
      <Container>
         <Text href="/Join" id="navSignup">
            {_js('Join')}
         </Text>
         <Divider />
         <Text href="#" id="navLogin" onClick={logIn}>
            {_js('Log In')}
         </Text>
      </Container>
   );
};

export default AuthIcons;
