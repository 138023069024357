import { color } from '@ifixit/primitives';
import { faShoppingCart } from '@fortawesome/pro-solid-svg-icons/faShoppingCart';
import { FaIcon } from '@ifixit/icons';
import { useCart } from '@ifixit/shopify-cart-sdk';
import Dropdown from './dropdown';
import Header from './mini_cart/header';
import Body from './mini_cart/body';
import Footer from './mini_cart/footer';

const MiniCartDropdown = () => {
   const cart = useCart();
   const products = cart.data?.lineItems;
   const hideMiniCart = () => {
      window.location = window.shared_constants.BaseURI('TAG_CHECKOUT_CART');
   };

   const simpleMiniCart = products && products.length > 10;

   return (
      <Dropdown
         id="miniCart"
         icon={
            <FaIcon
               icon={faShoppingCart}
               aria-label="my cart"
               id="miniCartDropdownToggle"
               focusable="false"
               color={color.white}
               boxSize={{ base: '20px', sm: '24px' }}
            />
         }
         label="Show cart"
         hasBadge={!!products?.length}
         onTrigger={instance => {
            if (simpleMiniCart) {
               instance.disable();
               hideMiniCart();
            }
         }}
      >
         <div
            id={simpleMiniCart ? 'hydra-mini-cart-simple' : ''}
            style={{
               width: '350px',
               textAlign: 'left',
            }}
         >
            <div className="mini-cart hydra-mini-cart header-dropdown">
               <Header />
               <Body />
               <Footer />
            </div>
         </div>
      </Dropdown>
   );
};

export default MiniCartDropdown;
