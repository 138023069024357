import styled, { css } from 'styled-components';
import { color } from '@ifixit/primitives';

// Having these defined will allow us to
// more easily transform the bars into an 'X' shape.
const Path1 = styled.path``;
const Path2 = styled.path``;
const Path3 = styled.path``;

const Container = styled.button<{ $isActive: boolean }>`
   display: flex;
   flex-shrink: 0;
   touch-action: manipulation;
   padding: 6px;

   path {
      transition: all 0.25s;
   }

   &:hover path {
      fill: ${color.gray[200]};
   }

   ${props =>
      props.$isActive &&
      css`
         ${Path1} {
            transform: rotate(45deg) translate(6px, -8px);
         }

         ${Path2} {
            transform: translateX(-100%);
            opacity: 0;
         }

         ${Path3} {
            transform: rotate(-45deg) translate(-10px, 0);
         }
      `}
`;

const MenuIcon = ({ isActive, onClick }: { isActive: boolean; onClick: () => void }) => (
   <Container role="button" aria-label="Menu" $isActive={isActive} onClick={onClick} id="menuIcon">
      <svg
         width="24"
         height="24"
         viewBox="0 0 24 24"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
         focusable="false"
      >
         <Path1
            transform={isActive ? 'rotate(45) translate(6, -8)' : ''}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 6C3 5.44772 3.40294 5 3.9 5H20.1C20.5971 5 21 5.44772 21 6C21 6.55228 20.5971 7 20.1 7H3.9C3.40294 7 3 6.55228 3 6Z"
            fill={color.white}
         />
         <Path2
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 12C3 11.4477 3.40294 11 3.9 11H20.1C20.5971 11 21 11.4477 21 12C21 12.5523 20.5971 13 20.1 13H3.9C3.40294 13 3 12.5523 3 12Z"
            fill={color.white}
         />
         <Path3
            transform={isActive ? 'rotate(-45) translate(-10, 0)' : ''}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 18C3 17.4477 3.40294 17 3.9 17H20.1C20.5971 17 21 17.4477 21 18C21 18.5523 20.5971 19 20.1 19H3.9C3.40294 19 3 18.5523 3 18Z"
            fill={color.white}
         />
      </svg>
   </Container>
);

MenuIcon.defaultProps = {
   isActive: false,
   /* eslint-disable-next-line @typescript-eslint/no-empty-function */
   onClick: () => {},
};

export default MenuIcon;
