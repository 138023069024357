import { Fragment, Component } from 'react';
import type { FunctionComponent } from 'react';
import styled from 'styled-components';
import { space } from '@ifixit/primitives';
import LanguageCard from './language_card';
import Language from './language';
import withLanguageForm from './with_language_form';
import LanguageThemeProvider from './language_theme_provider';
import {
   DropdownContainer,
   CardColumnContainer,
   CardColumn as RightColumn,
} from './card_dropdown_parts';

const LeftColumn = styled(RightColumn)`
   padding-right: ${space[6]};
`;

interface LanguageContainerProps {
   leftColumn: Array<Language>;
   rightColumn: Array<Language>;
   onClickLanguage: (langId: string) => void;
}

const LanguageContainer: FunctionComponent<LanguageContainerProps> = ({
   leftColumn,
   rightColumn,
   onClickLanguage,
}) => (
   <Fragment>
      <CardColumnContainer>
         <LeftColumn>
            {leftColumn.map((language: Language) => (
               <LanguageCard
                  key={language.isoCode}
                  language={language}
                  onClickLanguage={onClickLanguage}
               />
            ))}
         </LeftColumn>
         <RightColumn>
            {rightColumn.map((language: Language) => (
               <LanguageCard
                  key={language.isoCode}
                  language={language}
                  onClickLanguage={onClickLanguage}
               />
            ))}
         </RightColumn>
      </CardColumnContainer>
   </Fragment>
);

const LanguageContainerWithForm = withLanguageForm(LanguageContainer);

interface LanguageDropdownProps {
   className?: string;
   translationPreferencesUrl: string;
   languages: Array<Language>;
   isLightTheme: boolean;
   mobile: boolean;
}

export default class LanguageDropdown extends Component<LanguageDropdownProps> {
   public static defaultProps = {
      mobile: false,
   };

   constructor(props: LanguageDropdownProps) {
      super(props);
   }

   render() {
      const { className, languages, translationPreferencesUrl, isLightTheme, mobile } = this.props;

      const leftColumn = [];
      const rightColumn = [];

      for (const [index, language] of languages.entries()) {
         if (index % 2 === 0) {
            leftColumn.push(language);
         } else {
            rightColumn.push(language);
         }
      }

      return (
         <LanguageThemeProvider isLightTheme={isLightTheme} mobile={mobile}>
            <DropdownContainer className={className}>
               <LanguageContainerWithForm
                  leftColumn={leftColumn}
                  rightColumn={rightColumn}
                  translationPreferencesUrl={translationPreferencesUrl}
               />
            </DropdownContainer>
         </LanguageThemeProvider>
      );
   }
}
