import styled from 'styled-components';
import { color, space } from '@ifixit/primitives';
import withTippy from './with_tippy';
import type { TippyProps } from '@tippyjs/react';
import { FaIcon } from '@ifixit/icons';
import type { ReactNode } from 'react';

const UnstyledButton = styled.button`
   display: flex;
   position: relative;
   overflow: visible;
   margin-inline: ${space[4]};
   padding: 0;

   svg:first-child {
      transition: color 0.1s;
   }

   &:hover {
      text-decoration: none;

      svg:first-child {
         color: ${color.gray[200]};
      }
   }

   @media (max-width: 374px) {
      margin-inline: ${space[2]};
   }

   @media (max-width: 375px) {
      margin-inline: ${space[2]};
   }
`;

const Badge = styled.div`
   position: absolute;
   top: -2px;
   right: -4px;
   border: 2px solid ${color.black};
   border-radius: 99em;
   width: 12px;
   height: 12px;

   background-color: ${color.blue.ifixit};
`;

// @ts-expect-error TS(2345) FIXME: Argument of type 'StyledComponent<"a", any, {}, ne... Remove this comment to see the full error message
const UnstyledButtonWithTippy = withTippy(UnstyledButton);

type DropdownProps = Omit<TippyProps, 'children' | 'content'> & {
   id?: string;
   icon?: ReactNode;
   iconName?: any;
   hasBadge?: boolean;
   label: string;
   children: TippyProps['content'];
};

const Dropdown = ({
   id,
   children,
   icon,
   iconName,
   hasBadge,
   label,
   ...tippyOptions
}: DropdownProps) => {
   const passthroughProps = {
      'aria-label': label,
      role: 'button',
   };
   return (
      <UnstyledButtonWithTippy
         id={id}
         content={children}
         clickableElementProps={passthroughProps}
         {...tippyOptions}
      >
         {iconName ? <FaIcon icon={iconName} color={color.white} boxSize="24px" /> : icon}
         {!!hasBadge && <Badge className="headerBadge" />}
      </UnstyledButtonWithTippy>
   );
};

Dropdown.defaultProps = {
   /* eslint-disable-next-line @typescript-eslint/no-empty-function */
   onMount: () => {},
};

export default Dropdown;
