import { Menu } from 'Guide/menu';
import { MenuItemType } from '@ifixit/menu';
import { _js } from '@ifixit/localize';

export const getGuideSocialMediaAccounts = (repairUrl: string) => ({
   facebook: 'https://www.facebook.com/iFixit/',
   twitter: 'https://twitter.com/ifixit',
   tiktok: 'https://tiktok.com/@ifixit.com',
   instagram: 'https://www.instagram.com/ifixit/',
   youtube: 'https://www.youtube.com/user/iFixitYourself',
   repairOrg: repairUrl,
});

interface FooterType {
   menu1: Menu | null;
   menu2: Menu | null;
   menu3: Menu | null;
   partners: Menu | null;
   bottomMenu: Menu | null;
}

export const getGuideFooterMenus = (supportUrl: string): FooterType => ({
   menu1: {
      title: 'iFixit',
      items: [
         {
            type: MenuItemType.Link,
            name: _js('About Us'),
            url: '/about-us',
            description: null,
            trackingData: {
               eventCategory: 'Footer iFixit Link',
               eventAction: 'Footer iFixit Link - "About Us" - Click',
            },
         },
         {
            type: MenuItemType.Link,
            name: _js('Customer Support'),
            url: supportUrl,
            description: null,
            trackingData: {
               eventCategory: 'Footer iFixit Link',
               eventAction: 'Footer iFixit Link - "Customer Support" - Click',
            },
         },
         {
            type: MenuItemType.Link,
            name: _js('Discuss iFixit'),
            url: 'https://meta.ifixit.com',
            description: null,
            trackingData: {
               eventCategory: 'Footer iFixit Link',
               eventAction: 'Footer iFixit Link - "Discuss iFixit" - Click',
            },
         },
         {
            type: MenuItemType.Link,
            name: _js('Careers'),
            url: '/about-us/careers',
            description: null,
            trackingData: {
               eventCategory: 'Footer iFixit Link',
               eventAction: 'Footer iFixit Link - "Careers" - Click',
            },
         },
         {
            type: MenuItemType.Link,
            name: _js('Newsletter'),
            url: '/Newsletter',
            description: null,
            trackingData: {
               eventCategory: 'Footer iFixit Link',
               eventAction: 'Footer iFixit Link - "Newsletter" - Click',
            },
         },
         {
            type: MenuItemType.Link,
            name: _js('API'),
            url: '/api/2.0/doc',
            description: null,
            trackingData: {
               eventCategory: 'Footer iFixit Link',
               eventAction: 'Footer iFixit Link - "API" - Click',
            },
         },
      ],
   },
   menu2: {
      title: _js('Resources'),
      items: [
         {
            type: MenuItemType.Link,
            name: _js('Press'),
            url: '/Info/Media',
            description: null,
            trackingData: {
               eventCategory: 'Footer iFixit Link',
               eventAction: 'Footer iFixit Link - "Press" - Click',
            },
         },
         {
            type: MenuItemType.Link,
            name: _js('News'),
            url: '/News',
            description: null,
            trackingData: {
               eventCategory: 'Footer iFixit Link',
               eventAction: 'Footer iFixit Link - "News" - Click',
            },
         },
         {
            type: MenuItemType.Link,
            name: _js('Participate'),
            url: '/Participate',
            description: null,
            trackingData: {
               eventCategory: 'Footer iFixit Link',
               eventAction: 'Footer iFixit Link - "Participate" - Click',
            },
         },
         {
            type: MenuItemType.Link,
            name: _js('Pro Wholesale'),
            url: 'https://pro.ifixit.com/',
            description: null,
            trackingData: {
               eventCategory: 'Footer iFixit Link',
               eventAction: 'Footer iFixit Link - "Pro Wholesale" - Click',
            },
         },
         {
            type: MenuItemType.Link,
            name: _js('Retail Locator'),
            url: '/Retail',
            description: null,
            trackingData: {
               eventCategory: 'Footer iFixit Link',
               eventAction: 'Footer iFixit Link - "Retail Locator" - Click',
            },
         },
         {
            type: MenuItemType.Link,
            name: _js('For Manufacturers'),
            url: '/services',
            description: null,
            trackingData: {
               eventCategory: 'Footer iFixit Link',
               eventAction: 'Footer iFixit Link - "For Manufacturers" - Click',
            },
         },
      ],
   },
   menu3: {
      title: _js('Legal'),
      items: [
         {
            type: MenuItemType.Link,
            url: '/Info/Accessibility',
            name: _js('Accessibility'),
            description: null,
            trackingData: {
               eventCategory: 'Footer iFixit Link',
               eventAction: 'Footer iFixit Link - "Accessibility" - Click',
            },
         },
         {
            type: MenuItemType.Link,
            url: '/Info/Privacy',
            name: _js('Privacy'),
            description: null,
            trackingData: {
               eventCategory: 'Footer iFixit Link',
               eventAction: 'Footer iFixit Link - "Privacy" - Click',
            },
         },
         {
            type: MenuItemType.Link,
            url: '/Info/Terms_of_Use',
            name: _js('Terms'),
            description: null,
            trackingData: {
               eventCategory: 'Footer iFixit Link',
               eventAction: 'Footer iFixit Link - "Terms" - Click',
            },
         },
      ],
   },
   partners: null,
   bottomMenu: {
      title: _js('Licensing'),
      items: [
         {
            type: MenuItemType.Link,
            url: '/Info/Licensing',
            name: _js('Licensed under Creative Commons'),
            description: null,
            trackingData: {
               eventCategory: 'Footer iFixit Link',
               eventAction: 'Footer iFixit Link - "Licensed under Creative Commons" - Click',
            },
         },
      ],
   },
});

export const getNewsletterForm = () => ({
   title: _js('Stay in the loop'),
   subtitle: _js('Learn something new every month!'),
   callToActionButtonTitle: _js('Subscribe'),
   inputPlaceholder: _js('Enter your email'),
});

export const getStores = () => [
   {
      code: 'us',
      name: 'United States',
      url: 'https://www.ifixit.com/Store',
      currency: 'USD',
   },
   {
      code: 'eu',
      name: 'Europe',
      url: 'https://eustore.ifixit.com/',
      currency: 'EUR',
   },
];
